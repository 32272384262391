import * as React from "react";
import { graphql } from "gatsby";
import TemplatePage from "../../../../components/pages/templates/TemplatePage";

const Template = ({ data }) => (
  <TemplatePage
    title="Video Call Website Template | Uizard"
    category="Web App Templates"
    url="/templates/web-app-templates/video-messaging-web-app/"
    metaDescription="Got an idea for a video calling web app? Design a prototype with Uizard's video call web app template in just a few clicks! Try now with Uizard Pro."
    description="
    h2:Introducing Vid.io, our video messaging website design template
    <br/>
    Have an idea for a video messaging web app? Build a prototype with Uizard's video call app template design in just a few clicks! This <a:https://uizard.io/templates/web-app-templates/>web design template</a> offers several screens, all of them fully customizable and easy to use. Simply sign up to Uizard Pro, select the template, and get to work.
    <br/>
    h3:Use Vid.io to design the video call app of the future
    <br/>
    Vid.io was designed to impress. Boasting a modern and chic design, the template is ready to go straight out of the box, meaning you can <a:https://uizard.io/prototyping/>UX prototype</a> your own video messaging web app in minutes instead of days. Working with your team is also super easy, simply share the link to your Uizard project and collaborate in real-time like never before.
    <br/>
    h3:Video calling app design just got a whole lot easier
    <br/>
    As with all our Uizard Pro templates, the design of each screen is fully customizable. You can change the theme, colors, typography, and images according to your needs. Need an extra screen? You can easily build it with template components or use the help of our magic editor.
    "
    pages={[
      "A home dashboard page",
      "Workspace dashboard",
      "Folder file view",
      "Web app settings",
    ]}
    projectCode="yx9BwWdEW0fBPovwybM0"
    img1={data.image1.childImageSharp}
    img1alt="video call web app design template cover"
    img2={data.image2.childImageSharp}
    img2alt="video call web app design template folder screen"
    img3={data.image3.childImageSharp}
    img3alt="video call web app design template review screen"
    img4={data.image4.childImageSharp}
    img4alt="video call web app design template settings screen"
    img5={data.image5.childImageSharp}
    img5alt="video call web app design template summary"
  />
);

export default Template;

export const query = graphql`
  query ($language: String!) {
    locales: allLocale(filter: { language: { eq: $language } }) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }

    image1: file(
      relativePath: {
        eq: "templates/video-call-web-app/video-call-web-app-cover.png"
      }
    ) {
      childImageSharp {
        fluid(maxWidth: 1440, quality: 100) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
        }
      }
    }

    image2: file(
      relativePath: {
        eq: "templates/video-call-web-app/video-call-web-app-folder.png"
      }
    ) {
      childImageSharp {
        fluid(maxWidth: 1440, quality: 100) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
        }
      }
    }

    image3: file(
      relativePath: {
        eq: "templates/video-call-web-app/video-call-web-app-review.png"
      }
    ) {
      childImageSharp {
        fluid(maxWidth: 1440, quality: 100) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
        }
      }
    }

    image4: file(
      relativePath: {
        eq: "templates/video-call-web-app/video-call-web-app-settings.png"
      }
    ) {
      childImageSharp {
        fluid(maxWidth: 1440, quality: 100) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
        }
      }
    }

    image5: file(
      relativePath: {
        eq: "templates/video-call-web-app/video-call-web-app-summary.png"
      }
    ) {
      childImageSharp {
        fluid(maxWidth: 1440, quality: 100) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
        }
      }
    }
  }
`;
